import { API } from 'aws-amplify';
import configData from '@/config/config';

export async function getOmwUrl(apptNumber, datasetId) {
  if (configData?.omwLookup?.demo) return configData.omwLookup.demoUrl;
  const payload = {
    queryStringParameters: {
      datasetId,
    },
  };
  const data = await API.get('omw', `/on-my-way/search/${apptNumber}`, payload);
  return data?.url;
}
