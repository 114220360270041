<!--
  Component for looking up OMW links by appointment ID
-->
<template>
  <div class="container">
    <collapsible open-label="" closed-label="" :is-open="url ? false : true">
      <validation-observer v-slot="{ valid }">
        <section class="section">
          <div class="container">
            <article class="notification is-neutral">
              <validation-provider
                v-slot="{ errors }"
                rules="required"
                name="Appointment Number"
              >
                <p class="has-text-weight-semibold">
                  {{ $t('omw-viewer-appointment-number') }}
                </p>
                <b-input
                  v-model="appointmentNumber"
                  is-large
                  style="margin-bottom: 0.5em"
                ></b-input>
                <data-set-selector v-model="datasetId">
                  <template #title>
                    <div class="has-text-weight-semibold">
                      {{ $t('omw-resource-config-dataset') }}
                    </div>
                  </template>
                </data-set-selector>

                <b-button
                  :disabled="!valid || !appointmentNumber || !datasetId"
                  type="is-primary"
                  @click.prevent="onSubmit()"
                >
                  {{ $t('omw-viewer-search-label') }}
                </b-button>
                <span class="help is-white">{{ errors[0] }}</span>
              </validation-provider>
            </article>
            <p class="has-text-danger">{{ error }}</p>
          </div>
        </section>
      </validation-observer>
    </collapsible>
    <div v-if="url">
      <b-button type="is-link" class="my-1" @click="url = undefined">{{
        $t('omw-search-again')
      }}</b-button>
      <omw-lookup-result :key="key" :url="url" />
    </div>
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api';
import Collapsible from 'vue-collapsible-component';
import 'vue-collapsible-component/lib/vue-collapsible.css';
import {
  ValidationProvider,
  ValidationObserver,
  setInteractionMode,
  extend,
} from 'vee-validate';

import { getOmwUrl } from '@/services/lookup';
import DataSetSelector from '@/components/shared/DataSetSelector';
import OmwLookupResult from './OmwLookupResult';

export default defineComponent({
  name: 'OMWLookup',
  components: {
    ValidationProvider,
    ValidationObserver,
    DataSetSelector,
    OmwLookupResult,
    Collapsible,
  },
  data() {
    return {
      datasetId: undefined,
      appointmentNumber: undefined,
      url: undefined,
      error: '',
      key: 0,
    };
  },
  methods: {
    async onSubmit() {
      this.key++;
      this.error = null;
      const url = await getOmwUrl(this.appointmentNumber, this.datasetId);
      if (!url) {
        this.error = this.$t('omw-lookup-none-found', {
          apptNumber: this.appointmentNumber,
          datasetId: this.datasetId,
        });
        return;
      }
      this.url = url;
    },
  },
});
</script>

<style scoped>
.welcome-enter {
  opacity: 0;
}
.welcome-enter-active {
  transition: all 1s cubic-bezier(1, 0.5, 0.8, 1);
}
.welcome-leave-active {
  transition: 0.8s;
}
.welcome-leave-to {
  transform: translateY(200px);
  opacity: 0;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.fade-enter-active {
  transition: opacity 0.8s ease-in;
}
.fade-leave-active {
  transition: opacity 0.8s ease-out;
}

textarea {
  display: block;
  padding: 10px;
  margin: 10px 0 0 -10px;
  width: 340px;
  height: 360px;
  resize: none;
  overflow: auto;
}
</style>
