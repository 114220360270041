<template>
  <div>
    <slot name="title"></slot>
    <b-select aria-role="list" class="mb-1" @input="updateValue">
      <option v-for="dataset in datasets" :key="dataset" aria-role="listitem">
        {{ dataset }}
      </option>
    </b-select>
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'DataSetSelector',
  props: {
    value: {
      type: String,
      default: '',
    },
  },
  data() {
    return {};
  },
  computed: {
    datasets() {
      return this.$configData.customer.datasets;
    },
  },
  methods: {
    updateValue(value) {
      this.$emit('input', value);
    },
  },
});
</script>

<style>
white {
  color: 'white' !important;
}
</style>
